import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import SectionWrapper, { CarouseWrapper, TextWrapper } from './appSlider.style';

import { useTranslation } from "react-i18next"

const AppSlider = ({passedLocale}) => {
  const data = useStaticQuery(graphql`
    query {
      rutinaJson {
        appSlider {
          title
          description
          features {
            id
            icon
            title
            description
          }
          carousel {
            id
            title
            image {
              en {
                publicURL
              },
              es {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  const {
    title,
    description,
    features,
    carousel,
  } = data.rutinaJson.appSlider;

  const { t, i18n } = useTranslation()

  const glideOptions = {
    type: 'carousel',
    gap: 0,
    autoplay: 5000,
    perView: 1,
    animationDuration: 700,
  };

  return !passedLocale
    ? null
    :(
      <SectionWrapper>
        <Container>
          <CarouseWrapper>
            <GlideCarousel
              bullets={true}
              controls={false}
              numberOfBullets={3}
              options={glideOptions}
              carouselSelector="appFeatureSlider"
            >
              <Fragment>
                {carousel.map((item) => (
                  <GlideSlide key={`feature-side--key${item.id}`}>
                    <Image src={item.image[passedLocale].publicURL} alt={item.title} />
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          </CarouseWrapper>
          <TextWrapper>
            <Heading content={t(title)} />
            <Text content={t(description)} />
            {features.map((item) => (
              <FeatureBlock
                key={`app-feature--key${item.id}`}
                iconPosition="left"
                icon={<Image
                  src={item.icon}
                  height={34} alt={t(item.title)}
                  style={{ marginRight: 22 }}
                />}
                title={<Heading as="h3" content={t(item.title)} />}
                description={<Text content={t(item.description)} />}
              />
            ))}
          </TextWrapper>
        </Container>
      </SectionWrapper>
    );
};

export default AppSlider;
