import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { mediaRecordOutline } from 'react-icons-kit/typicons/mediaRecordOutline';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../appClassic.style';
import SectionWrapper, { FeatureWrapper } from './keyFeatures.style';

import { useTranslation } from "react-i18next"

const KeyFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      rutinaJson {
        keyFeatures {
          slogan
          title
          features {
            id
            color
            icon
            title
            height
            description
          }
        }
      }
    }
  `);
  const { slogan, title, features } = data.rutinaJson.keyFeatures;
  
  const { t } = useTranslation()

  const [showFadeItems, setShowFadeItems] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => { setShowFadeItems(true) }, 200);
  }, []);

  return (
    <SectionWrapper id="motivation">
      <Container>
        <SectionHeader>
          <Fade up when={showFadeItems}>
            <Heading as="h5" content={t(slogan)} />
            <Heading content={t(title)} />
          </Fade>
        </SectionHeader>
        <FeatureWrapper>
          {features.map((item) => (
            <Fade up delay={100 * item.id} key={`key-feature--key${item.id}`} when={showFadeItems}>
              <FeatureBlock
                style={{ '--color': `${item.color}` }}
                icon={
                  <Fragment>
                    <Icon className="plus" icon={plus} />
                    <Icon className="circle" icon={mediaRecordOutline} />
                    <Image src={item.icon} height={item.height} alt={t(item.title)} />
                    <Icon className="star" icon={starOutline} />
                  </Fragment>
                }
                title={<Heading as="h3" content={t(item.title)} />}
                description={<Text content={t(item.description)} />}
              />
            </Fade>
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default KeyFeatures;
