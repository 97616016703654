import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import { Icon } from 'react-icons-kit';
import { mediaRecordOutline } from 'react-icons-kit/typicons/mediaRecordOutline';
import { plus } from 'react-icons-kit/typicons/plus';
import { starOutline } from 'react-icons-kit/typicons/starOutline';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Tab, { Panel } from 'common/components/Tabs';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../appClassic.style';
import SectionWrapper, { TabArea, ImageDiv } from './featureTab.style';

import { useTranslation } from "react-i18next"

const FeatureTab = ({passedLocale}) => {
  const { t, i18n } = useTranslation()

  const data = useStaticQuery(graphql`
    query {
      rutinaJson {
        featuresTab {
          slogan
          title
          tab {
            id
            color
            icon
            title
            description
            image {
              en {
                publicURL
              },
              es {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  const { slogan, title, tab } = data.rutinaJson.featuresTab;

  const [showFadeItems, setShowFadeItems] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => { setShowFadeItems(true) }, 200);
  }, []);

  return !passedLocale
    ? null
    :(
      <SectionWrapper id="keyFeatures">
        <Container>
          <SectionHeader>
            <Fade up when={showFadeItems}>
              <Heading as="h5" content={t(slogan)} />
              <Heading content={t(title)} />
            </Fade>
          </SectionHeader>
          <Fade up delay={100} when={showFadeItems}>
            <TabArea>
              <Tab active={0}>
                {tab.map((item) => (
                  <Panel
                    title={
                      <FeatureBlock
                        style={{ '--color': `${item.color}` }}
                        iconPosition="left"
                        icon={
                          <Fragment>
                            <Icon className="plus" icon={plus} />
                            <Icon className="circle" icon={mediaRecordOutline} />
                            <Image src={item.icon} height={40} alt={t(item.title)} />
                            <Icon className="star" icon={starOutline} />
                          </Fragment>
                        }
                        title={<Heading as="h3" content={t(item.title)} />}
                        description={<Text content={t(item.description)} />}
                      />
                    }
                    key={`app-tab--key${item.id}`}
                  >
                    <Fade when={showFadeItems}>
                      <ImageDiv>
                        <Image src={item.image[passedLocale].publicURL} alt={t(item.title)} />
                      </ImageDiv>
                    </Fade>
                  </Panel>
                ))}
              </Tab>
            </TabArea>
          </Fade>
        </Container>
      </SectionWrapper>
    );
};

export default FeatureTab;
