import React from 'react';
import Fade from 'react-reveal/Fade';
// import { Icon } from 'react-icons-kit';
// import { playCircle } from 'react-icons-kit/fa/playCircle';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Rating from 'common/components/Rating';
import Container from 'common/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImagesWithIcon,
  BannerImage,
  // ButtonGroup,
} from './banner.style';

import bannerImgIcon from 'common/assets/image/rutina/icon.png';

import bannerImgEn from 'common/assets/image/rutina/en-banner-1.png';
import bannerImg2En from 'common/assets/image/rutina/en-banner-2.png';
import googleImgEn from 'common/assets/image/rutina/en-google-play-badge.png';
import appleImgEn from 'common/assets/image/rutina/en-apple-store-badge.svg';
import bannerImgEs from 'common/assets/image/rutina/es-banner-1.png';
import bannerImg2Es from 'common/assets/image/rutina/es-banner-2.png';
import googleImgEs from 'common/assets/image/rutina/es-google-play-badge.png';
import appleImgEs from 'common/assets/image/rutina/es-apple-store-badge.svg';

import { useTranslation } from 'react-i18next';

const Banner = ({ passedLocale }) => {
  const { t, i18n } = useTranslation();

  const [showFadeItems, setShowFadeItems] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setShowFadeItems(true);
    }, 200);
  }, []);

  let images =
    passedLocale === 'en'
      ? {
          bannerImg: bannerImgEn,
          bannerImg2: bannerImg2En,
          googleImg: googleImgEn,
          appleImg: appleImgEn,
        }
      : {
          bannerImg: bannerImgEs,
          bannerImg2: bannerImg2Es,
          googleImg: googleImgEs,
          appleImg: appleImgEs,
        };

  return !passedLocale ? null : (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          {/* <Fade up>
              <RatingInfo>
                <Rating rating={4} />
                4.9 of 5 By <img src={microsoft} alt="Microsoft" />
              </RatingInfo>
            </Fade> */}
          <Fade up delay={100} when={showFadeItems}>
            <Heading as="h1" content={t('Rutina')} />
          </Fade>
          <Fade up delay={200} when={showFadeItems}>
            <Text content={t('banner-text')} />
            <Text content={t('banner-download')} />
          </Fade>
          <Fade up delay={300} when={showFadeItems}>
            <div style={{ display: 'flex' }}>
              <a
                href="https://play.google.com/store/apps/details?id=com.gabocapde.rutina"
                target="_blank"
                style={{ width: 190, maxWidth: '50%' }}
              >
                <Image src={images.googleImg} alt="Banner" />
              </a>
              <a
                href="https://apps.apple.com/us/app/rutina/id1578546245"
                target="_blank"
                style={{ width: 150, maxWidth: '40%' }}
              >
                <Image
                  src={images.appleImg}
                  alt="Banner"
                  style={{ height: '100%' }}
                />
              </a>
            </div>
            {/* <ButtonGroup>
                <Button className="primary" title="Start Free trail" />
                <Button
                  className="text"
                  variant="textButton"
                  icon={<Icon icon={playCircle} />}
                  iconPosition="left"
                  title="Watch Video"
                />
              </ButtonGroup> */}
          </Fade>
        </BannerContent>
        <BannerImagesWithIcon>
          {/* <div style={{ width: '39%', display: 'flex', justifyContent: 'space-evenly' }}> */}
          <div style={{ marginRight: 0, zIndex: 1, display: 'flex' }}>
            <BannerImage>
              <Fade up delay={100} when={showFadeItems}>
                <Image
                  src={images.bannerImg2}
                  alt="bannerImg2"
                  style={{
                    height: 450,
                    marginBottom: 20,
                    borderRadius: '1%',
                    opacity: 0.3,
                  }}
                />
              </Fade>
            </BannerImage>
          </div>
          <div style={{ marginRight: -20, zIndex: 100, display: 'flex' }}>
            <BannerImage>
              <Fade up delay={100} when={showFadeItems}>
                <Image
                  src={bannerImgIcon}
                  alt="bannerImgIcon"
                  style={{ height: 150, marginBottom: 50 }}
                />
              </Fade>
            </BannerImage>
          </div>
          <div style={{ marginRight: 140, zIndex: 1, display: 'flex' }}>
            <BannerImage>
              <Fade up delay={100} when={showFadeItems}>
                <Image
                  src={images.bannerImg}
                  alt="bannerImg"
                  style={{ height: 500, borderRadius: '1%', opacity: 0.9 }}
                />
              </Fade>
            </BannerImage>
          </div>
          {/* </div> */}
        </BannerImagesWithIcon>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
